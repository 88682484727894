import { mobile, backend, creator, web } from "../assets";
import { Ashish, Pooja, Sapna, Bipin, Aarti, Rohit } from "../assets/Team";
import database from "../assets/database.png";
import app from "../assets/app.png";
import website from "../assets/website.png";
import destop from "../assets/destop.png";

import { mlm, nidhi, recharge } from "../assets/project";
export const navLinks = [
  {
    id: "Home",
    title: "Home",
    path: "/",
  },
  {
    id: "services",
    title: "Services",
    path: "/services",
  },
  {
    id: "about",
    title: "About",
    path: "/about",
  },
  {
    id: "Privacy Policy",
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    id: "contact us ",
    title: "Contact us",
    path: "/contact",
  },
  {
    id: "careers",
    title: "Careers",
    path: "/careers",
  },
  {
    id: "project  ",
    title: "Project",
    path: "/project",
  },
  {
    id: "Payment Plan  ",
    title: "Payment Plan",
    path: "/paymentPlan",
  },
];
const services = [
  {
    title: "Website Development",
    icon: web,
  },
  {
    title: "Gaming App Development",
    icon: mobile,
  },
  {
    title: "E-commerce Websites",
    icon: web, // Repeating the 'web' icon for E-commerce Websites
  },
  {
    title: "Bulk SMS",
    icon: backend, // Repeating the 'backend' icon for Bulk SMS
  },
  {
    title: "NBFC Software",
    icon: backend, // Repeating the 'backend' icon for NBFC Software
  },
  // {
  //   title: "Applications Development",
  //   icon: mobile, // Repeating the 'mobile' icon for Applications Development
  // },
  // {
  //   title: "Nidhi Software",
  //   icon: backend, // Repeating the 'backend' icon for Nidhi Software
  // },
  // {
  //   title: "Social Media Marketing",
  //   icon: creator, // Repeating the 'creator' icon for Social Media Marketing
  // },
  // {
  //   title: "Hotel Management Software",
  //   icon: backend, // Repeating the 'backend' icon for Hotel Management Software
  // },
  // {
  //   title: "Search Engine Optimization",
  //   icon: web, // Repeating the 'web' icon for Search Engine Optimization
  // },
  // {
  //   title: "Hospital Management Software",
  //   icon: backend, // Repeating the 'backend' icon for Hospital Management Software
  // },
];

const Benifit = [
  {
    title: "24 hour customer service",
    icon: web,
  },
  {
    title: "Affordable rates for Websites",
    icon: mobile,
  },
  {
    title: "Fast Application Creation Process",
    icon: backend,
  },
  {
    title: "SEO Support",
    icon: creator,
  },
  {
    title: "App repair warranty for 3 Months",
    icon: creator,
  },
];

const testimonials = [
  {
    testimonial:
      "ADT Pvt. Ltd. delivered a website that perfectly captures our company's essence and vision. The team was professional and responsive throughout the entire process.",
    name: "Brij Pandey",
    designation: "Chairman",
    company: "National Drywall Solution",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    testimonial:
      "Working with ADT Pvt. Ltd. was a fantastic experience. Their expertise in web development and design helped us achieve a website that exceeded our expectations.",
    name: "Mithlesh Poddar",
    designation: "Chairman",
    company: "Shanti Interiors",
    image: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    testimonial:
      "The team at ADT Pvt. Ltd. demonstrated exceptional creativity and technical skills. Our new website is both visually stunning and highly functional.",
    name: "Prem Sharma",
    designation: "Chairman",
    company: "AP Interiors",
    image: "https://randomuser.me/api/portraits/men/2.jpg",
  },
];

const projects = [
  {
    name: "Nidhi Software",
    description:
      "Aasakya Softech comes with the RD FD software that helps to maintain all financial issues of your company. The expert developer team made it with the core value of Nidhi software.",
    tags: [
      {
        name: "financial",
        color: "blue-text-gradient",
      },
      {
        name: "management",
        color: "green-text-gradient",
      },
      {
        name: "software",
        color: "pink-text-gradient",
      },
    ],
    image: nidhi, // replace with the correct image URL
    source_code_link: "https://github.com/",
  },
  {
    name: "MLM Software",
    description:
      "If you are looking for customized MLM Software in Jaipur, Rajasthan then Aaskya Softech provides this software at very low cost and high quality. We guarantee to give you extraordinary features and integrate the compensation plan into this software.",
    tags: [
      {
        name: "MLM",
        color: "blue-text-gradient",
      },
      {
        name: "custom",
        color: "green-text-gradient",
      },
      {
        name: "software",
        color: "pink-text-gradient",
      },
    ],
    image: mlm, // replace with the correct image URL
    source_code_link: "https://github.com/",
  },
  {
    name: "E-Commerce Recharge Utility",
    description:
      "Get your secure and reliable Recharge Software to start your own recharge software by us. Our software will help you grow your business by fulfilling the needs and requirements of your customers, including bulk SMS API, payment API, recharge API, multi recharge software, and many more.",
    tags: [
      {
        name: "ecommerce",
        color: "blue-text-gradient",
      },
      {
        name: "recharge",
        color: "green-text-gradient",
      },
      {
        name: "utility",
        color: "pink-text-gradient",
      },
    ],
    image: recharge, // replace with the correct image URL
    source_code_link: "https://github.com/",
  },
  // {
  //   name: "CRM Software",
  //   description:
  //     "We can help you with a range of services from data cleansing and campaign management to custom API integrations and bespoke reports. Our mobile marketing experts can even help you with the right database and marketing insights to kickstart your lead generation!",
  //   tags: [
  //     {
  //       name: "CRM",
  //       color: "blue-text-gradient",
  //     },
  //     {
  //       name: "marketing",
  //       color: "green-text-gradient",
  //     },
  //     {
  //       name: "API",
  //       color: "pink-text-gradient",
  //     },
  //   ],
  //   image: "https://via.placeholder.com/150", // replace with the correct image URL
  //   source_code_link: "https://github.com/",
  // },
];

const Team = [
  {
    name: "Aarti Dixit",
    role: "HR manager",
    image: Aarti,
    source_code_link: "https://www.linkedin.com/",
  },
  {
    name: "Sapna ",
    role: "Marketing Exective",
    image: Sapna,
    source_code_link: "https://www.linkedin.com/",
  },
  {
    name: "Pooja",
    role: "Marketing Exective",
    image: Pooja,
    source_code_link: "https://www.linkedin.com/",
  },
  {
    name: "Ashish",
    role: "Frontend Developer",
    image: Ashish,
    source_code_link: "https://www.linkedin.com/",
  },
  {
    name: "Bipin Singh ",
    role: "FullStack Developer",
    image: Bipin,
    source_code_link: "https://www.linkedin.com/",
  },
];

const servicescard = [
  {
    title: "MOBILE APP",
    image: app, // Mobile app development image
    subPoints: [
      "Native Apps",
      "Web Apps",
      "Hybrid Apps",
      "Progressive Web Apps (PWA)",
    ],
  },
  {
    title: "WEBSITE APP",
    image: website, // Website development image
    subPoints: [
      "Static Website",
      "Dynamic Website",
      "E-commerce Website",
      "Custom Web Application",
    ],
  },
  {
    title: "DESKTOP APP",
    image: destop, // Desktop app development image
    subPoints: ["B2B Apps", "Internal Apps", "Consumer Apps"],
  },
  {
    title: "DATABASE",
    image: database,
    subPoints: [
      "Data Modeling",
      "Database Design",
      "Integration Data",
      "Performance Tuning",
      "Data Backup and Recovery",
    ],
  },
];

export { Benifit, Team, services, testimonials, projects, servicescard };
