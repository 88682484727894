import React, { useEffect, useState } from "react";
import { Hero } from "../components/Ui/Hero";
import Feedbacks from "../components/Ui/Feedbacks";
import ManagementQuotes from "../components/Ui/ManagementQuotes";
import JoinUsForm from "../components/Ui/JoinUsForm";
import Footers from "../components/Ui/Footer";
// import Globe from "../components/Globe";
import Navbar from "../components/Ui/Navbar";
import ServiceForm from "./ServiceForm";
const Home = () => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(prevOpen => !prevOpen); // Functional update to use the previous state
    }, 6000);
  
    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <ManagementQuotes />
      <Feedbacks />
      {/* <Globe /> */}
      <JoinUsForm />
      <Footers />
      <ServiceForm open={open} setOpen={setOpen}/>
    </>
  );
};

export default Home;
