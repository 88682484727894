import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { styles } from "../../styles";

import { slideIn } from "../../utils/motion";
import api_services from "../../services/api_service";

function Careersform() {
  const formRef = useRef();
  const skills = [
    "PHP",
    "WordPress",
    "Node JS",
    "React JS",
    "React Native",
    "Flutter",
    "Python",
    "Data Science",
    "ML",
  ];

  const levels = ["Beginner", "Intermediate", "Advanced", "N/A"];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    nationality: "",
    religion: "",
    gender: "",
    age: "",
    maritalStatus: "",
    vacancyAppliedFor: "",
    whatsappNumber: "",
    skills: {},
    yearsOfExperience: "",
    wifi: "",
    wfhComfort: "",
    officeComfort: "",
    shortTermGoals: "",
    longTermGoals: "",
    incentivesIdea: "",
    growingTogetherUnderstanding: "",
    salaryExpectation: "",
    additionalInfo: "",
  });

  const [tab, setTab] = useState(0);

  const [loading, setLoading] = useState(false);

  const handleSkillSelect = (skill, level) => {
    setFormData((prevData) => ({
      ...prevData,
      skills: {
        ...prevData.skills,
        [skill]: level,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setTab(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await api_services.sendCarrier(formData);
      setLoading(false);
      alert("Send message Successfully");
      setFormData({
        name: "",
        nationality: "",
        religion: "",
        gender: "",
        age: "",
        maritalStatus: "",
        vacancyAppliedFor: "",
        whatsappNumber: "",
        skills: {},
        yearsOfExperience: "",
        wifi: "",
        wfhComfort: "",
        officeComfort: "",
        shortTermGoals: "",
        longTermGoals: "",
        incentivesIdea: "",
        growingTogetherUnderstanding: "",
        salaryExpectation: "",
        additionalInfo: "",
      });
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <motion.div
      variants={slideIn("left", "tween", 0.2, 1)}
      className=" flex-1 bg-black-100 p-8 rounded-2xl"
    >
      <h3 className="text-center font-bold text-3xl mb-2">
        Careers with Aasakya Digitals
      </h3>
      <h1 className="text-center text-lg">Build a future you believe in</h1>

      {tab === 0 ? (
        <form
          ref={formRef}
          onSubmit={handleNext}
          className="mt-12 flex flex-col gap-8"
        >
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Full Name</span>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="What's your good name?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Email</span>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="What's your email?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Nationality</span>
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              placeholder="What's your Nationality?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Religion</span>
            <select
              name="religion"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.religion}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                What's your Religion?
              </option>
              <option value="christianity">Christianity</option>
              <option value="islam">Islam</option>
              <option value="hinduism">Hinduism</option>
              <option value="buddhism">Buddhism</option>
              <option value="other">Other</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Gender</span>
            <select
              name="gender"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                What's your Gender?
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">Non-binary</option>
              <option value="prefer-not-to-say">Prefer not to say</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Age</span>
            <select
              name="age"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.age}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                What's your Age?
              </option>
              <option value="18-22">18-22</option>
              <option value="23-27">23-27</option>
              <option value="28-32">28-32</option>
              <option value="Above 32">Above 32</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Marital Status</span>
            <select
              name="maritalStatus"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.maritalStatus}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                What's your Marital Status?
              </option>
              <option value="18-22">Married</option>
              <option value="23-27">Unmarried</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Vacancy Applied for
            </span>
            <select
              name="vacancyAppliedFor"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.vacancyAppliedFor}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                What's your Skill set?
              </option>
              <option value="hr-intern">HR Intern</option>
              <option value="hr">HR</option>
              <option value="project-manager-it">Project Manager (IT)</option>
              <option value="social-media-marketer-intern">
                Social Media Marketer (Intern)
              </option>
              <option value="sr-social-media-marketer">
                Sr. Social Media Marketer
              </option>
              <option value="senior-digital-marketing-manager">
                Senior Digital Marketing Manager
              </option>
              <option value="mobile-developer">Mobile Developer</option>
              <option value="web-developer-php-wordpress">
                Web Developer (PHP & WordPress)
              </option>
              <option value="web-developer-mern-mean">
                Web Developer (MERN/MEAN)
              </option>
              <option value="frontend-developer">Frontend Developer</option>
              <option value="backend-developer">Backend Developer</option>
              <option value="database-analyst">Database Analyst</option>
              <option value="devops-engineer">DevOps Engineer</option>
              <option value="web-tester">Web Tester</option>
              <option value="sales-manager">Sales Manager</option>
              <option value="sales-executive">Sales Executive</option>
              <option value="graphic-designer">Graphic Designer</option>
              <option value="video-editor">Video Editor</option>
              <option value="accountant">Accountant</option>
              <option value="customer-support-executive">
                Customer Support Executive
              </option>
              <option value="tester">Tester</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">WhatsApp Number</span>
            <input
              type="text"
              name="whatsappNumber"
              placeholder="What's your WhatsApp Number?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.whatsappNumber}
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            className={`bg-tertiary mx-auto py-3 px-16 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary`}
          >
            Next
          </button>
        </form>
      ) : (
        <form
          ref={formRef}
          className="mt-12 flex flex-col gap-8"
          onSubmit={handleSubmit}
        >
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Skill Set Score</span>
            <div className="container mx-auto p-4">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 border text-left"></th>
                    {levels.map((level) => (
                      <th
                        key={level}
                        className="p-2 text-slate-900 border text-center"
                      >
                        {level}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {skills.map((skill) => (
                    <tr key={skill} className="border-b">
                      <td className="p-2 border">{skill}</td>
                      {levels.map((level) => (
                        <td
                          key={`${skill}-${level}`}
                          className="p-2 border text-center"
                        >
                          <div className="flex justify-center items-center">
                            <input
                              type="radio"
                              id={`${skill}-${level}`}
                              name={skill}
                              checked={formData.skills[skill] === level}
                              onChange={() => handleSkillSelect(skill, level)}
                              className="form-radio h-4 w-4 text-blue-600"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Total Years of Experience
            </span>
            <select
              name="yearsOfExperience"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.yearsOfExperience}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                What's your total years of experience?
              </option>
              <option value="0-6-months">0-6 Months</option>
              <option value="1-2-years">1-2 Years</option>
              <option value="3-4-years">3-4 Years</option>
              <option value="4+years">4+ Years</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Do you have proper Wi-Fi Connection
            </span>
            <select
              name="wifi"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.wifi}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Please select value
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Are you comfortable in WFH setup
            </span>
            <select
              name="wfhComfort"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.wfhComfort}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Please select value
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="not-exactly">Not Exactly</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Are you also comfortable with coming to the office when required
            </span>
            <select
              name="officeComfort"
              className="bg-tertiary py-4 px-6 text-white rounded-lg outline-none border-none font-medium"
              value={formData.officeComfort}
              onChange={handleChange}
              required
            >
              <option value="" disabled selected>
                Please select value
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="trip">
                Only if the company pays for the trip
              </option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Short Term Career Goals
            </span>
            <input
              type="text"
              name="shortTermGoals"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.shortTermGoals}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Long Term Career Goals
            </span>
            <input
              type="text"
              name="longTermGoals"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              value={formData.longTermGoals}
              onChange={handleChange}
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              What's your idea on "Incentives given to an Employee"
            </span>
            <input
              type="text"
              name="incentivesIdea"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.incentivesIdea}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              What's you're understanding with the term "Growing Together"
            </span>
            <input
              type="text"
              name="growingTogetherUnderstanding"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              value={formData.growingTogetherUnderstanding}
              onChange={handleChange}
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Your Salary Expectation
            </span>
            <input
              type="text"
              name="salaryExpectation"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.salaryExpectation}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">
              Anything else that you want us to know ?
            </span>
            <input
              type="text"
              name="additionalInfo"
              placeholder="Enter your answer"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
              value={formData.additionalInfo}
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            className={`bg-tertiary mx-auto py-3 px-16 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary`}
            disabled={loading ? true : false}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      )}
    </motion.div>
  );
}

export default Careersform;
