import React from "react";
import { motion } from "framer-motion";
import website from "../../assets/website.gif";
import mobile from "../../assets/phone-settings.gif";
import logo from "../../assets/logo-design.gif";
import software from "../../assets/virus.gif";
import api from "../../assets/api.gif";
import game from "../../assets/gamepad.gif";

import { styles } from "../../styles";
import { fadeIn, textVariant } from "../../utils/motion";
import { SectionWrapper } from "../../hoc";
const EasyandPower = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>AASAKYA Services-</p>
        <h2 className={`${styles.sectionHeadText}`}>Easy & Powerful</h2>
      </motion.div>

      <motion.div variants={fadeIn("", "", 0.1, 1)}>
        <div className="grid mt-10 grid-cols-1 md:grid-cols-2 gap-10">
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img
                src={website}
                alt="icon"
                class="w-12 h-12 mr-2 rounded-full"
              />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                Website Development
              </h3>
            </div>

            <p className="text-lg text-white">
              AASAKYA Softech is one of the most reliable web developments well
              known for their services and ultimately help you in generating the
              most remarkable results as well as boosts up your business.
            </p>
          </div>
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img
                src={mobile}
                alt="icon"
                class="w-12 h-12 mr-2 rounded-full"
              />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                Mobile APP Development
              </h3>
            </div>
            <p className="text-lg text-white">
              Our skilled team of developers is dedicated to provide you the
              best mobile app development service. They engineered to bring
              growth to your business by creating innovative applications
            </p>
          </div>
        </div>
        <div className="grid mt-10 grid-cols-1 md:grid-cols-2 gap-10">
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img src={logo} alt="icon" class="w-12 h-12 mr-2 rounded-full" />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                Logo Design
              </h3>
            </div>
            <p className="text-lg text-white">
              We have been consistently designing logos from the past few years.
              Our professional team of logo designers work together to make our
              company and your brand successful.
            </p>
          </div>
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img
                src={software}
                alt="icon"
                class="w-12 h-12 mr-2 rounded-full"
              />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                Software Development
              </h3>
            </div>
            <p className="text-lg text-white">
              Profession and best skill team of QSR always give the best
              software solution. Simple layout with unbeatable output Software
              Solutions is our signature.
            </p>
          </div>
        </div>
        <div className="grid mt-10 grid-cols-1 md:grid-cols-2 gap-10">
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img src={api} alt="icon" class="w-12 h-12 mr-2 rounded-full" />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                API Integration
              </h3>
            </div>
            <p className="text-lg text-white">
              Start sending SMS from any application in minutes. Our powerful
              SMS Services allow you to send & receive SMS, reports, manage
              contacts.
            </p>
          </div>
          <div className="green-pink-gradient shadow-card bg-tertiary rounded-[20px] py-5 px-12 ">
            <div class="flex items-center mb-6">
              <img src={game} alt="icon" class="w-12 h-12 mr-2 rounded-full" />
              <h3 class="text-xl font-semibold mb-3 text-white ml-3">
                Game Development
              </h3>
            </div>
            <p className="text-lg text-white">
              Unlock the fun and addictive experiences with our Game Development
              services. The team is working to give engaging experience to the
              audience.
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SectionWrapper(EasyandPower, "");
