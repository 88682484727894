import React from "react";
import { Tilt } from "react-tilt";

import { motion } from "framer-motion";

import { styles } from "../../styles";
import { Benifit } from "../../constant/index";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const PROCESS = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <h2 className={styles.sectionHeadText}>CREATION PROCESS</h2>
      </motion.div>

      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
      >
        <span className="text-white">1. DISCUSSING THE PLAN:</span> we will
        discuss with you and analyze the needs of the application you want
      </motion.p>
      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
      >
        <span className="text-white">2. BUILD :</span> this is the process of
        designing and building an application
      </motion.p>
      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px] mb-6"
      >
        <span className="text-white">3.REVISION :</span> After the application
        is complete, we will provide an opportunity for you to check and improve
        the suitability of the application needs.
        <p>👉Note :- Revision is complementary in testing phase only!</p>
      </motion.p>
    </>
  );
};

export default SectionWrapper(PROCESS, "");
