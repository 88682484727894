import React from "react";
import { motion } from "framer-motion";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant } from "../utils/motion";
import creditCardIcon from "../assets/credit-card.png";
import paypalIcon from "../assets/paypal.png";
import bankTransferIcon from "../assets/Bank Transfer.png";
import onlineWalletsIcon from "../assets/Wallets.png";
import qrCodeIcon from "../assets/QR.jpg"; // Replace with your actual QR code image
import { styles } from "../styles";

const PaymentPlans = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>Payment Plans</p>
        <h2 className={styles.sectionHeadText}>
          Flexible Payment Options for Your Convenience
        </h2>
      </motion.div>

      <motion.div variants={fadeIn("", "", 0.1, 1)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-16">
          <div>
            <h3 className="text-2xl font-semibold mb-5 text-white">
              Payment Methods
            </h3>
            <ul className="space-y-4">
              <PaymentMethod
                icon={creditCardIcon}
                title="Credit/Debit Card"
                description="Make secure payments using your credit or debit card. We accept Visa, Mastercard, and American Express."
              />
              <PaymentMethod
                icon={paypalIcon}
                title="PayPal"
                description="Conveniently pay with your PayPal account. PayPal allows you to pay using your balance, bank account, or credit card."
              />
              <PaymentMethod
                icon={bankTransferIcon}
                title="Bank Transfer"
                description="Transfer funds directly from your bank account to ours. Please contact us for our bank account details."
              />
              <PaymentMethod
                icon={onlineWalletsIcon}
                title="Online Wallets"
                description="Use popular online wallets like Apple Pay, Google Pay, and Samsung Pay for quick and easy transactions."
              />
              <PaymentMethod
                icon={qrCodeIcon}
                title="QR Code / UPI"
                description="Scan the QR code or use our UPI ID for instant payments."
              />
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-5 text-white">
              Payment Terms
            </h3>
            <p className="text-lg text-white">
              We offer flexible payment options to make your transactions smooth
              and hassle-free.
            </p>
            <p className="mt-4 text-gray-300">
              Please make sure to use the correct payment method and details.
              For any queries regarding payment, feel free to contact our
              support team.
            </p>
            <div className="mt-4">
              <img
                src={qrCodeIcon}
                alt="QR Code"
                className="w-40 h-40 mx-auto"
              />
              <p className="text-center text-white mt-2">
                UPI ID: your-upi-id@bank
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

const PaymentMethod = ({ icon, title, description }) => {
  return (
    <li className="flex items-center space-x-4">
      <img src={icon} alt={title} className="w-10 h-10" />
      <div>
        <h4 className="text-lg font-semibold text-white">{title}</h4>
        <p className="text-base text-gray-300">{description}</p>
      </div>
    </li>
  );
};

export default SectionWrapper(PaymentPlans, "");
