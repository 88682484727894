import React, { useEffect, useState } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from "./components/Ui/Navbar";
import Services from "./Pages/Services";
import Home from "./Pages/Home";
import About from "./Pages/About";
import PaymentPlan from "./Pages/PaymentPlan";
import Privacypolicy from "./Pages/Privacypolicy";
import Contact from "./Pages/Contact";
import Works from "./components/Ui/Works";

import Team from "./components/Ui/team";
import Mission from "./components/Ui/mission";
import Footers from "./components/Ui/Footer";
import Preloader from "./components/Ui/Preloader";
import CosmicEntry from "./components/Ui/CosmicEntry";
import { StarsCanvas } from "./components/canvas";
import EasyandPower from "./components/Ui/EasyandPower";
import Benefit from "./components/Ui/Benefit";
import PROCESS from "./components/Ui/PROCESS";
import Project from "./Pages/Project";
import Careers from "./Pages/Careers";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [firstTimeUser, setFirstTimeUser] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("visited");
    if (hasVisitedBefore) {
      setFirstTimeUser(false);
    } else {
      localStorage.setItem("visited", "true");
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Home />
        </>
      ),
    },
    {
      path: "/services",
      element: (
        <>
          <Navbar />
          <Services />
          <EasyandPower />
          <Footers />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <Navbar />
          <About />
          <Works />
          <Team />
          <Mission />
          <PROCESS />
          <Benefit />
          <Footers />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <Navbar />
          <Contact />
          <StarsCanvas />
          <Footers />
        </>
      ),
    },
    {
      path: "/careers",
      element: (
        <>
          <Navbar />
          <Careers />
          <StarsCanvas />
          <Footers />
        </>
      ),
    },
    {
      path: "/paymentPlan",
      element: (
        <>
          <Navbar />
          <PaymentPlan />
          <Footers />
        </>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <>
          <Navbar />
          <Privacypolicy />
          <Footers />
        </>
      ),
    },
    {
      path: "/project",
      element: (
        <>
          <Project />
        </>
      ),
    },
  ]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : firstTimeUser ? (
        <CosmicEntry />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
};

export default App;
