import { Footer } from "flowbite-react";
import {
  BsGeoAlt,
  BsEnvelope,
  BsWhatsapp,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import { motion } from "framer-motion";

function Footers() {
  const phoneNumber = "8700729156";
  const message = "Hello, I would like to know more about your services.";
  return (
    <Footer bgDark>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="w-full bg-primary px-4 py-6 sm:flex sm:items-center sm:justify-between text-white"
      >
        <div className="w-full ">
          <h2 className="text-2xl font-semibold mb-3 flex md:justify-center">
            Connect with us
          </h2>
          <div className=" flex md:flex-row flex-col  gap-6 md:justify-center">
            <div className="text-sm flex items-center  ">
              <BsWhatsapp className="mr-2 text-green-500 text-2xl" /> 9152081883
            </div>
            <div className="text-sm  flex items-center">
              <a href="mailto:contact@aasakyadigitals.com" className="flex items-center gap-1">
                <BsEnvelope className="mr-2  text-white-500 text-2xl" />{" "}
                contact@aasakyadigitals.com
              </a>
            </div>
            <div className="text-sm  flex items-center">
              <BsGeoAlt className="mr-2  text-red-500 text-2xl" /> LG-278 Gaur
              City Centre Noida
            </div>
          </div>
        </div>

        <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
          <Footer.Icon
            href="https://www.facebook.com/people/Aasakya-Digitals/61564753844782/"
            target="blank"
            icon={BsFacebook}
            className="hover:text-blue-500 transition-colors duration-300"
          />
          <Footer.Icon
            href="https://www.instagram.com/aasakyadigital?igsh=YTZyYXg5M2U2OWtm"
            target="blank"
            icon={BsInstagram}
            className="hover:text-pink-500 transition-colors duration-300"
          />
          <Footer.Icon
            href="https://www.linkedin.com/in/aasakya-digital-technologies-5976bb311?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="blank"
            icon={BsLinkedin}
            className="hover:text-blue-400 transition-colors duration-300"
          />
          <Footer.Icon
            href="https://github.com/aasakyadigitals"
            target="blank"
            icon={BsGithub}
            className="hover:text-gray-600 transition-colors duration-300"
          />
          <Footer.Icon
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
              message
            )}`}
            icon={BsWhatsapp}
            className="hover:text-green-400 transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </motion.div>
      <div className="text-center p-3 bg-primary text-white">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          &copy; {new Date().getFullYear()} ADT Pvt. Ltd. All rights reserved.
        </motion.p>
      </div>
    </Footer>
  );
}

export default Footers;
