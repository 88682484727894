import React, { useRef, useState } from "react";
import { Dialog } from "@mui/material";

const ServiceForm = ({ open, setOpen }) => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dataSend = {
      name: form.name,
      email: form.email,
      subject: `Message from ${form.name}`,
      message: form.message,
    };

    try {
      const res = await fetch("https://bcdadt.vercel.app/api/send-mail", {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (res.status > 199 && res.status < 300) {
        setLoading(false);
        alert("Thank you. I will get back to you as soon as possible.");
        setForm({
          name: "",
          email: "",
          message: "",
        });
        setOpen(!open)
      } else {
        setLoading(false);
        alert("Ahh, something went wrong. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert("Ahh, something went wrong. Please try again.");
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          backgroundColor: "transparent", // Change dialog background color
          borderRadius: 7,
        },
      }}
      open={open}
      onClose={() => setOpen(!open)}
    >
      <div className=" p-11 bg-[#100d25]">
        <h1 className=" text-center text-2xl text-white">Get in Touch</h1>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="mb-6">
            <h1 className="text-white">Your Name</h1>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="What's your good name?"
              className=" bg-tertiary w-full p-3 rounded-lg outline-none  text-white"
              required
            />
          </div>
          <div className="mb-6">
            <h1 className="text-white">Your Email</h1>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="What's your web address?"
              className=" bg-tertiary w-full p-3 rounded-lg outline-none  text-white"
              required
            />
          </div>
          <div>
            <h1 className="text-white">Your Message</h1>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What you want to say?"
              className="bg-tertiary p-3 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium w-full"
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full p-4 bg-tertiary rounded-lg mt-4 text-white ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </Dialog>
  );
};

export default ServiceForm;
