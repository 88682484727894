import React, { useEffect, useState } from "react";

const CosmicEntry = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (animationComplete) {
      setTimeout(() => {
        // Navigate to main content after animation completes
        window.location.href = "/";
      }, 1000);
    }
  }, [animationComplete]);

  return (
    <div
      className={`cosmic-entry-container ${
        animationComplete ? "fade-out" : ""
      }`}
    >
      <div className="cosmic-entry-animation"></div>
    </div>
  );
};

export default CosmicEntry;
