import React, { useState, useEffect } from "react";
import PHPLogo from "../Ui/icons/php.png";
import PythonLogo from "../Ui/icons/python.png";
import JavaLogo from "../Ui/icons/java.png";
import JSLogo from "../Ui/icons/js.png";
import ReactLogo from "../Ui/icons/react.png";
import CompanyLogo from "../../assets/logo.jpg";

const Preloader = () => {
  const [allLogosLoaded, setAllLogosLoaded] = useState(false);

  useEffect(() => {
    const logos = document.querySelectorAll(".logo");
    let delay = 0;

    // Animate each logo entering the container box
    logos.forEach((logo) => {
      setTimeout(() => {
        logo.classList.add("scale-100");
      }, delay);
      delay += 200; // Delay each logo's animation
    });

    // Shake the container once all logos are loaded
    setTimeout(() => {
      document.querySelector(".logos-container").classList.add("animate-shake");
      setTimeout(() => {
        setAllLogosLoaded(true);
      }, 500); // Adjusted delay to set allLogosLoaded after the shake animation
    }, delay + 1000); // Adjusted delay to match the total animation duration of logos
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-900">
      <div
        className={`logos-container flex flex-wrap justify-center space-x-4 ${
          allLogosLoaded ? "hidden" : ""
        }`}
      >
        {/* Logos of different technologies and strategies */}
        <img
          src={PHPLogo}
          alt="PHP Logo"
          className="logo transform scale-0 transition-all duration-500 ease-in-out w-12 h-12"
        />
        <img
          src={PythonLogo}
          alt="Python Logo"
          className="logo transform scale-0 transition-all duration-500 ease-in-out w-12 h-12"
        />
        <img
          src={JavaLogo}
          alt="Java Logo"
          className="logo transform scale-0 transition-all duration-500 ease-in-out w-12 h-12"
        />
        <img
          src={JSLogo}
          alt="JavaScript Logo"
          className="logo transform scale-0 transition-all duration-500 ease-in-out w-12 h-12"
        />
        <img
          src={ReactLogo}
          alt="React Logo"
          className="logo transform scale-0 transition-all duration-500 ease-in-out w-12 h-12"
        />
      </div>
      <div
        className={`flex flex-wrap justify-center space-x-4 ${
          allLogosLoaded ? "" : "hidden"
        }`}
      >
        <img
          src={CompanyLogo}
          alt="Company Logo"
          className={`company-logo transform scale-100  transition-opacity duration-500 ease-in-out  w-20 h-20 rounded-full border border-yellow-500 ${
            allLogosLoaded ? "opacity-1" : "opacity-0"
          }`}
        />
      </div>
    </div>
  );
};

export default Preloader;
