import React from "react";
import { Tilt } from "react-tilt";

import { motion } from "framer-motion";

import { styles } from "../../styles";
import { Benifit } from "../../constant/index";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Mission = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>Our</p>
        <h2 className={styles.sectionHeadText}>VISION AND MISSION</h2>
      </motion.div>

      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]"
      >
        <span className="text-white "> &#8226; VISION:</span> At Aasakya
        Digitals we consider our customers our family , that's why we provide
        you with such a website that helps your business reach the heights it
        deserves.
      </motion.p>
      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-3xl leading-[30px] mb-6           "
      >
        <span className="text-white"> &#8226; MISSION:</span> Aasakya Digitals
        Mission :-Our company believes in Vasudev family, believes in the coming
        future, we work at every level and with every businesses who are
        passionate to reach the heights possible.
      </motion.p>
    </>
  );
};

export default SectionWrapper(Mission, "");
