import React from "react";
import { motion } from "framer-motion";

import { styles } from "../styles";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant } from "../utils/motion";
const Privacypolicy = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>Privacy Policy</p>
        <h2 className={styles.sectionHeadText}>Our Commitment to Privacy</h2>
      </motion.div>

      <motion.div
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-secondary text-[17px] max-w-6xl leading-[30px]"
      >
        <p>
          At Aasakya, we are committed to protecting your privacy. This Privacy
          Policy outlines how we collect, use, and safeguard your personal
          information when you visit our website or use our services.
        </p>
        <p>
          <span className="text-white">Data Collection:</span> When you visit
          our website, we may collect certain information automatically,
          including your IP address, browser type, referring website, and other
          usage details. We may also collect personal information that you
          voluntarily provide to us, such as your name, email address, and phone
          number.
        </p>
        <p>
          <span className="text-white">Use of Information:</span> We use the
          information we collect to provide and improve our services,
          communicate with you, and customize your experience. We may also use
          your information to send you promotional emails or newsletters, but
          you can opt out of receiving these communications at any time.
        </p>
        <p>
          <span className="text-white">Data Security:</span> We take the
          security of your information seriously and employ appropriate
          technical and organizational measures to protect it from unauthorized
          access, alteration, disclosure, or destruction.
        </p>
        <p>
          <span className="text-white">Third-Party Disclosure:</span> We may
          share your information with third-party service providers who assist
          us in operating our website, conducting our business, or serving you.
          These parties are contractually obligated to keep your information
          confidential and secure.
        </p>
        <p>
          <span className="text-white">Cookies:</span> We may use cookies and
          similar tracking technologies to enhance your browsing experience and
          gather information about how you use our website. You can control
          cookies through your browser settings and opt out of certain types of
          tracking.
        </p>
        <p>
          <span className="text-white">Third-Party Links:</span> Our website may
          contain links to third-party websites or services that are not owned
          or controlled by us. We are not responsible for the privacy practices
          or content of these third-party sites.
        </p>
        <p>
          <span className="text-white">Changes to this Policy:</span> We reserve
          the right to update or modify this Privacy Policy at any time. Any
          changes will be effective immediately upon posting the revised policy
          on our website.
        </p>
        <p>
          By using our website or services, you consent to the terms of this
          Privacy Policy. If you have any questions or concerns about our
          privacy practices, please contact us.
        </p>
      </motion.div>
    </>
  );
};

export default SectionWrapper(Privacypolicy, "");
