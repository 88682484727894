import React from "react";
import Navbar from "../components/Ui/Navbar";
import Footers from "../components/Ui/Footer";

const Project = () => {
  return (
    <div className="flex flex-col h-screen ">
      <div className="h-16">
        {" "}
        {/* Set a fixed height for the navbar */}
        <Navbar />
      </div>
      <div className="flex-grow overflow-hidden ">
        <iframe
          src="https://adt-demo.vercel.app/"
          className="w-full h-full border-none"
          title="Demo"
        />
      </div>
    </div>
  );
};

export default Project;
