import React from "react";
import { motion } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { styles } from "../../styles";
import { Tilt } from "react-tilt";
import { AshishIMG, Aarti1, } from "../../assets/Team";
import logo from "../../assets/logo.jpg";

const ManagementQuotes = () => {
  // Sample data for management quotes and images
  const managementQuotes = [
    {
      quote:
        "Our mission is to make complex technology simple and accessible for everyone.",
      image: Aarti1,
    },
    {
      quote:
        "Innovation is at the core of what we do, driving us to deliver exceptional solutions.",
      image: AshishIMG,
    },
  ];
  const managementQuotes1 = [
    {
      name: "Aarti",
      quote:
        "Collaboration and teamwork are essential for achieving great results in the tech industry.",
      image: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
      name: "Ashish",
      quote:
        "Passion for technology and dedication to our clients are what set us apart.",
      image: "https://randomuser.me/api/portraits/men/4.jpg",
    },
  ];

  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>
          {" "}
          Hear what our management team has to say
        </p>
        <p className={`${styles.sectionHeadText}`}>Management Quotes</p>
      </motion.div>

      <motion.div
        variants={fadeIn("", "", 0.1, 1)}
        className="flex flex-col md:flex-row mt-6"
      >
        <div className="w-full md:w-1/4 md:pr-8 mb-8 md:mb-0">
          {managementQuotes1.map((quote, index) => (
            <div key={index}>
              <QuoteCard index={index} name={quote.name} quote={quote.quote} />
            </div>
          ))}
        </div>
        <div className="w-full md:w-3/4 mt-6">
          {managementQuotes.map((quote, index) => (
            <div key={index} className="flex items-center mb-8">
              <div className=" w-20 md:h-20  rounded-full flex justify-center items-center  overflow-hidden h-10 ">
                <img
                  src={quote.image}
                  alt={quote.name}
                  className="w-full h-full  object-cover"
                />
              </div>
              <div className="text-white ml-4">{quote.quote}</div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

const QuoteCard = ({ index, name, quote }) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 25,
          scale: 1.05,
          speed: 300,
          glare: true,
          "max-glare": 0.5,
        }}
        className="w-full"
      >
        <div className="bg-tertiary p-5 mt-4 rounded-2xl">
          {/* <div className="flex items-center mb-2">
            <img
              src={logo}
              alt="Logo"
              className="w-6 h-6 mr-2 rounded-full border border-yellow-500 "
            /> */}
          <div className="text-lg font-semibold text-white">{name}</div>
          {/* </div> */}
          <div className="text-white">{quote}</div>
        </div>
      </Tilt>
    </motion.div>
  );
};

export default SectionWrapper(ManagementQuotes, "");
