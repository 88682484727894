import axios from "axios";

const baseUrl = "https://adt-backend.vercel.app";

const api_services = {
  sendCarrier: async (payload) =>{
    return await axios.post(`${baseUrl}/api/careers`, payload)
  }

};

export default api_services;
