import React, { useState } from "react";
import { motion } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { styles } from "../../styles";

const JoinUsForm = () => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit the form only if the user is subscribed to the newsletter
    if (subscribed) {
      // Here you can implement the logic to submit the form
      setSubmitted(true);
      // Clear the email input after submission
      setEmail("");
    } else {
      alert("Please subscribe to our newsletter to join us.");
    }
  };

  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>Join Us</p>
        <p className={`${styles.sectionHeadText}`}>
          Submit your business proposal agreement
        </p>
      </motion.div>

      <motion.form
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-6 flex flex-col md:flex-row items-center"
        onSubmit={handleSubmit}
      >
        <div className="flex-grow md:pr-4 mb-4 md:mb-0">
          <label htmlFor="email" className="block mb-2 text-white">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            className="w-full bg-gray-200 px-4 py-2 rounded-lg"
            required
          />
        </div>
        <button
          type="submit"
          className="mt-auto bg-primary px-6 py-3 rounded-lg text-white font-semibold transition duration-300 hover:bg-primary-dark md:ml-4"
        >
          Submit
        </button>
      </motion.form>

      {submitted && (
        <motion.div
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-4 text-white"
        >
          Your proposal agreement has been submitted successfully.
        </motion.div>
      )}
    </>
  );
};

export default SectionWrapper(JoinUsForm, "");
