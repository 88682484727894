import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";

import { styles } from "../styles";

import { SectionWrapper } from "../hoc";
import { servicescard } from "../constant";
import { fadeIn, textVariant } from "../utils/motion";

const ServicesCard = ({ index, title, description, subPoints, image }) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 25,
          scale: 1.05,
          speed: 300,
          glare: true,
          "max-glare": 0.5,
        }}
        className="bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full"
      >
        <div className="relative w-full h-[230px]">
          <img
            src={image}
            alt="project_image"
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>

        <div className="mt-5">
          <h3 className="text-white font-bold text-[24px]">{title}</h3>
          <p className="mt-2 text-secondary text-[14px]">{description}</p>
          <ul className="mt-2 ml-3 text-secondary text-[14px]  list-disc">
            {subPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      </Tilt>
    </motion.div>
  );
};
const Services = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>Our Services</p>
        <h2 className={`${styles.sectionHeadText}`}>What We Offer</h2>
      </motion.div>

      <div className="w-full flex">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-3 text-secondary text-[17px] max-w-6xl leading-[30px]"
        >
          Explore our services tailored to meet your needs and exceed your
          expectations. Each service is designed to provide you with innovative
          solutions, expert guidance, and exceptional results.
        </motion.p>
      </div>

      <div className="mt-20 flex flex-wrap gap-7 ">
        {servicescard.map((project, index) => (
          <ServicesCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
    </>
  );
};
export default SectionWrapper(Services, "");
