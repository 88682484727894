import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import Contactform from "../components/Ui/Contact";
import { SectionWrapper } from "../hoc";
import { slideIn } from "../utils/motion";
import { GlobeDemo } from "../components/canvas/earthnetwork";

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dataSend = {
      name: form.name,
      email: form.email,
      subject: `Message from ${form.name}`,
      message: form.message,
    };

    try {
      const res = await fetch("http://localhost:8000/email/sendEmail", {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (res.status > 199 && res.status < 300) {
        setLoading(false);
        alert("Thank you. I will get back to you as soon as possible.");
        setForm({
          name: "",
          email: "",
          message: "",
        });
      } else {
        setLoading(false);
        alert("Ahh, something went wrong. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert("Ahh, something went wrong. Please try again.");
    }
  };

  return (
    <div className="relative z-0">
      <div
        className={`xl:mt-12 flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
      >
        <Contactform />

        <motion.div
          variants={slideIn("right", "tween", 0.2, 1)}
          className="xl:flex-1 xl:h-auto md:h-[550px] h-[350px]"
        >
          {/* <EarthCanvas /> */}
          <GlobeDemo />
        </motion.div>
      </div>
    </div>
  );
};

export default SectionWrapper(Contact, "contact");
